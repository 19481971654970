<template v-if="uuid">
	<div v-if="init">
		<RetiredApprove :uuid="uuid" :details="orderData" />
	</div>
</template>

<script>
import RetiredApprove from "@/view/module/approve/RetiredApprove";
import { GetOrder } from "@/core/lib/cart.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "checkout-approve",
	data() {
		return {
			uuid: null,
			orderData: new Object(),
			init: false,
		};
	},
	components: {
		RetiredApprove,
	},
	created() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getOrder();
	},
	methods: {
		getOrder() {
			const _this = this;
			GetOrder(_this.uuid)
				.then((data) => {
					_this.orderData = data;
					_this.$nextTick(() => {
						_this.init = true;
					});
				})
				.catch((error) => {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
};
</script>
